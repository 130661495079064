import Swiper from 'swiper';
import 'swiper/css';

new Swiper('.swiper-latest-funded-projects', {
  slidesPerView: 1,
  spaceBetween: 24,
  breakpoints: {
    460: { slidesPerView: 1.5 },
    680: { slidesPerView: 2.5 },
    980: { slidesPerView: 3 },
  },
});